/* .CSS-container {
    width: 45vw;
} */

.CSSgen-container {
    padding: 40px 40px 40px 0;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
    border-radius: 5px;
}

.CSS-output {
    width: 100%;
    height: 20vh;
}

.CSS-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 50vw;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 10px;
  }
  
  .input-label {
    font-size: 14px;
    font-weight: bold;
  }
  
  .CSS-input {
    width: 80%;
    height: 200px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .HTML-input {
    width: 80%;
    height: 200px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .HTML-input:focus {
    box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
    background: rgba(255, 255, 255, 0.25);
  }

  .CSS-input:focus {
    box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
    background: rgba(255, 255, 255, 0.25);
  }
  
  .CSS-output {
    width: 55vw;
    margin-left: 40px;
    height: 400px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    font-size: 16px;
    font-weight: bold;
  }

  .output-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  