.issue-container {
    width: 45vw;
    height: 65vh;
    padding: 40px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
  }
  
  .issue-container > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .issue-container > div > h3,
  .issue-container > div > p {
    white-space: normal;
    word-wrap: break-word;
  }
  