@import '@fortawesome/fontawesome-svg-core/styles.css';

.App {
  text-align: center;
}

.portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: 20px;
}

.glassmorphism-card {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
  border-radius: 8px;
  padding: 32px;
  text-align: center;
  max-width: 400px;
}

.project-details {
  margin: 0;
  padding: 0;
  width: 60%;
  margin-left: 50px;
  margin-right: 20px;
}

.project-list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.project-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.project-card h2 {
  margin-bottom: 8px;
}

.project-card p {
  color: black;
}

.project-card {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  height: 50px;
}

.project-card:hover {
  transform: rotateY(180deg);
}

.project-front,
.project-back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backface-visibility: hidden;
}

.project-front img {
  width: 100%;
}

.project-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-back p:first-of-type {
  margin-bottom: -10px;
}

.project-back h2,
.project-back p {
  color: black;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  z-index: 999;
}

.sidebar-icon {
  margin-bottom: 20px;
  font-size: 24px;
  cursor: pointer;
}

.sidebar-icon a {
  color: rgb(34, 33, 33);
}

.sidebar-icon:hover a,
.sidebar-icon:focus {
  color: #327d5e;
  text-decoration-color: #327d5e;
  -moz-text-decoration-color: #327d5e;
  outline: none;
}

.sidebar-icon:active {
  color: #000;
}