/* AccessKeyLogin.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-image: url('background-image.jpg'); Replace with your background image */
    background-size: cover;
    background-position: center;
    background-blur: 10px; /* Apply the glassmorphism effect using a backdrop filter */
  }
  
  .login-box {
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white background for glass effect */
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    backdrop-filter: blur(10px); /* Additional blur for glass effect */
  }
  
  .access-key-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgb(0, 0, 0);
  }
  
  .login-button {
    padding: 10px 20px;
    background-color: rgba(0, 123, 255, 0.8);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .welcome-box {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    backdrop-filter: blur(10px);
  }
  