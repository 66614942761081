
.chat-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px 20px 35px 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
  }

  .chat-container p {
    font-size: 14px;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .user-message {
    background-color: #e6f2ff;
    padding: 10px;
    border-radius: 5px;
  }
  
  .bot-message {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: right;
  }

  #chat-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .chat-input {
    width: 80%;
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 3px;
    resize: vertical;
    overflow-y: auto;
    margin-top: 20px;
  }

  .autofill {
    width: 150px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.264);
  }

  .autofill:hover {
    transition: all 0.2s ease-in-out;
    background: #327d5e;
    color: #fff;
  }
  
  .chat-send {
    width: 80px;
    padding-top: 0;
    margin: 0;
    height: 205px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.264);
    cursor: pointer;
    font-size: 16px;
    padding: 5px 15px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
  }

  .chat-send:hover {
    transition: all 0.2s ease-in-out;
    background: #327d5e;
    color: #fff;
  }
  
  .feedback-container {
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        /* width: 50%; */
        margin: auto;
        /* margin-top: 120px; */
        /* margin-bottom: 500px; */
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: opacity 0.5s ease;
  }
  
  .gpt-error {
    color: red;
  }

  .learner-name {
    margin-left: 15px;
    width: 362px;
  }
  .learner-pronoun {
    margin-left: 15px;
    width: 341px;
  }
  .learner-question {
    margin-left: 15px;
    width: 399px;
  }
  .custom-prompts {
    margin-left: 15px;
    width: 342px;
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #1a73e8;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s infinite linear;
    margin: 20px auto;
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: absolute;
    width: 25%;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    /* width: 200vw;
    height: 200vh;
    background-color: rgba(0, 0, 0, 0.842); */
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .gpt-error {
    color: red;
    margin-top: 10px;
    display: none;
  }
  
  .gpt-error.visible {
    display: block;
  }
  