/* .chat-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
  } */
  
  .message {
    margin-bottom: 10px;
  }
  
  .user-message {
    background-color: #e6f2ff;
    padding: 10px;
    border-radius: 5px;
  }
  
  .bot-message {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: right;
  }
  
  .voice-button {
    margin-top: 10px;
  }
  
  .voice-button button {
    padding: 8px 12px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .voice-button button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .gpttts-container {
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        width: 90%;
        margin: auto;
        /* margin-top: 100px; */
        /* margin-bottom: 500px; */
        padding: 30px;
  }

  .captureButtons {
    margin: 15px;
  }

  .captureButtons button {
    margin: 5px;
  }

  .recordButton {
    font-size: 16px;
    /* font-weight: 600; */
    border-radius: 4px;
    /* background: #5BA485; */
    padding: 5px 15px;
    /* color: #fff;
    outline: none;
    border: none; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.264);
}

.sendButton {
  margin-left: 10px;
  margin-top: 0px;
  height: 105px;
}

.inputArea {
  border-radius: 4px;
  width: 70%;
  height: 100px;
}

.inputForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatBox {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.recordButton:hover {
    transition: all 0.2s ease-in-out;
    background: #327d5e;
    color: #fff;
}

.error {
    color: red;
}

.audio-box {
  display: none;
}

  