.removed {
    display: none;
}

.videoContainer {
    display: flex;
    height: 500px;
    margin-right: 650px;
}

.videoContainer button {
    border-radius: 4px;
    cursor: pointer;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.264);
}

.videoContainer button:hover {
    transition: all 0.2s ease-in-out;
    background: #327d5e;
    color: #fff;
}

.videoView {
    position: absolute;
    z-index: 0;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
    padding: 10px 10px 5px 10px;
}

.videoView p {
    position: absolute;
    margin-top: 100px;
    z-index: 2;
    padding: 5px;
    border: 1px dashed white;
    background-color: rgba(255, 0, 0, 0.85);
    font-size: 14px;
    color: white;
}

.highlighter {
    position: absolute;
    z-index: 1;
    border: 1px dashed white;
    background: rgba(0, 255, 0, 0.20);
}